import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { thunk } from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

// Load reducers
import accountNotificationsReducer from './account-notifications/reducer';
import appReducer from './app/reducer';
import adviserClientPoliciesReducer from './adviser-client-policies/reducer';
import agencyGroupDetailsReducer from './agency-group-details/reducer';
import agencyGroupUsersReducer from './agency-group-users/reducer';
import addAgencyGroupUserReducer from './add-agency-group-user/reducer';
import appearanceReducer from './appearance/reducer';
import mobileMenuReducer from './mobile-menu/reducer';
import profileReducer from './profile/reducer';
import policiesReducer from './policies/reducer';
import premiumBreakdownReducer from './premium-breakdown/reducer';
import policyBenefitsReducer from './policy-benefits/reducer';
import policyTransactionHistoryReducer from './policy-transaction-history/reducer';
import policyAdministratorsReducer from './policy-administrators/reducer';
import policyThirdPartiesReducer from './policy-third-parties/reducer';
import policyIncomeHistoryReducer from './policy-income-history/reducer';
import policyBonusHistoryReducer from './policy-bonus-history/reducer';
import policyPaymentHistoryReducer from './policy-payment-history/reducer';
import userReducer from './user/reducer';
import invitationReducer from './invitation/reducer';
import challengeIdentityReducer from './challenge-identity/reducer';
import notificationPreferencesReducer from './notification-preferences/reducer';
import sessionReducer from './session/reducer';
import customerMessagesReducer from './customer-messages/reducer';
import sendCustomerMessageReducer from './send-customer-message/reducer';
import customerMessageCategoriesReducer from './customer-message-categories/reducer';
import recoveryQuestionsReducer from './recovery-questions/reducer';
import factorsReducer from './factors/reducer';
import stylesReducer from './styles/reducer';
import changePasswordReducer from './change-password/reducer';
import customerDocumentsReducer from './customer-documents/reducer';
import originatingCompaniesReducer from './originating-companies/reducer';
import uiTogglesReducer from './ui-toggles/reducer';
import removeAgencyGroupUserReducer from './remove-agency-group-user/reducer';
import routerHistoryReducer from './router-history/reducer';
import updateProfileDetailsReducer from './update-profile-details/reducer';
import verificationCodeReducer from './verification-code/reducer';
import fundSwitchReducer from './policy-fund-switch/reducer';
import policyFundFactsheetReducer from './policy-fund-factsheet/reducer';
import challengeAccountReducer from './challenge-account/reducer';
import activationStepsReducer from './activation-steps/reducer';
import adviserPortfoliosReducer from './adviser-portfolios/reducer';
import adviserPortfolioDetailsReducer from './adviser-portfolio-details/reducer';
import createAdviserPortfolioReducer from './create-adviser-portfolio/reducer';
import adviserPortfolioInstructionReducer from './adviser-portfolio-instruction/reducer';
import adviserPortfolioAvailableFundsReducer from './adviser-portfolio-available-funds/reducer';
import fundProfileReducer from './fund-profile/reducer';
import portfolioPolicyProfileReducer from './portfolio-policy-profile/reducer';
import adviserPortfolioAvailablePoliciesReducer from './adviser-portfolio-available-policies/reducer';
import eligibilityReducer from './eligibility/reducer';
import adviserBackOfficeIntegrationsReducer from './adviser-back-office-integrations/reducer';
import fundSelectorReducer from './fund-selector/reducer';
import singlePremiumPaymentReducer from './single-premium-payment/reducer';
import adviserPortfolioAbiSectorColorsReducer from './adviser-portfolio-abi-sector-colors/reducer';
import schemeNominationReducer from './scheme-nomination/reducer';
// Get middleware for history
const history = createBrowserHistory();

// Build middleware
const middleware = applyMiddleware(routerMiddleware(history), thunk);

// Core reducers to combine
const reducers = {
    accountNotifications: accountNotificationsReducer,
    activationSteps: activationStepsReducer,
    app: appReducer,
    appearance: appearanceReducer,
    changePassword: changePasswordReducer,
    customerMessages: customerMessagesReducer,
    customerMessageCategories: customerMessageCategoriesReducer,
    factors: factorsReducer,
    form: formReducer,
    fundProfile: fundProfileReducer,
    invitation: invitationReducer,
    mobileMenu: mobileMenuReducer,
    notificationPreferences: notificationPreferencesReducer,
    originatingCompanies: originatingCompaniesReducer,
    policies: policiesReducer,
    portfolioPolicyProfile: portfolioPolicyProfileReducer,
    profile: profileReducer,
    recoveryQuestions: recoveryQuestionsReducer,
    removeAgencyGroupUser: removeAgencyGroupUserReducer,
    router: connectRouter(history),
    routerHistory: routerHistoryReducer,
    challengeIdentity: challengeIdentityReducer,
    sendCustomerMessage: sendCustomerMessageReducer,
    session: sessionReducer,
    styles: stylesReducer,
    uiToggles: uiTogglesReducer,
    user: userReducer,
    verificationCode: verificationCodeReducer,
    customerDocuments: customerDocumentsReducer,
    addAgencyGroupUser: addAgencyGroupUserReducer,
    adviserClientPolicies: adviserClientPoliciesReducer,
    agencyGroupDetails: agencyGroupDetailsReducer,
    adviserPortfolioDetails: adviserPortfolioDetailsReducer,
    adviserPortfolios: adviserPortfoliosReducer,
    adviserPortfolioAvailableFunds: adviserPortfolioAvailableFundsReducer,
    adviserPortfolioAvailablePolicies: adviserPortfolioAvailablePoliciesReducer,
    adviserPortfolioAbiSectorColors: adviserPortfolioAbiSectorColorsReducer,
    agencyGroupUsers: agencyGroupUsersReducer,
    policyBenefits: policyBenefitsReducer,
    premiumBreakdown: premiumBreakdownReducer,
    policyTransactionHistory: policyTransactionHistoryReducer,
    policyIncomeHistory: policyIncomeHistoryReducer,
    policyBonusHistory: policyBonusHistoryReducer,
    policyPaymentHistory: policyPaymentHistoryReducer,
    policyAdministrators: policyAdministratorsReducer,
    policyThirdParties: policyThirdPartiesReducer,
    updateProfileDetails: updateProfileDetailsReducer,
    policyFundSwitch: fundSwitchReducer,
    policyFundFactsheet: policyFundFactsheetReducer,
    challengeAccount: challengeAccountReducer,
    createAdviserPortfolio: createAdviserPortfolioReducer,
    adviserPortfolioInstruction: adviserPortfolioInstructionReducer,
    eligibility: eligibilityReducer,
    adviserBackOfficeIntegrations: adviserBackOfficeIntegrationsReducer,
    fundSelector: fundSelectorReducer,
    singlePremiumPayment: singlePremiumPaymentReducer,
    schemeNomination: schemeNominationReducer,
};

let enhancer;

// Make our store with our reducers and middleware
if (process.env.REACT_APP_DEBUG_STORE === 'true') {
    enhancer = composeWithDevTools(middleware);
} else {
    enhancer = compose(middleware);
}

// Make our store with our reducers and middleware
const store = createStore(connectRouter(history)(combineReducers(reducers)), enhancer);

export { history, store };
